import { useQuery } from "@tanstack/react-query";
import getJobForm from "../services/get-job-form";

const useJobLoader = (jobKey) => {
  return useQuery({
    queryKey: ["jobLoader", jobKey],
    queryFn: () => getJobForm(jobKey),
  });
};

export default useJobLoader;
