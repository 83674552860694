import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { agGridStyles } from "../styles/ag-grid-styles";
import PropTypes from "prop-types";

/**
 * A wrapper component for AG Grid React that provides consistent styling and behavior
 * across the Policy Checker application.
 *
 * @component
 * @param {Object} props
 * @param {Object|Function} props.gridRef - Reference to the grid instance
 * @param {Array} props.columnDefs - Column definitions for the grid
 * @param {Array} props.rowData - Data to be displayed in the grid
 * @param {Function} props.onRowClicked - Callback function triggered when a row is clicked
 * @param {string} [props.domLayout='normal'] - Grid layout mode: 'autoHeight' adjusts to content, 'normal' uses container height
 * @returns {React.Component} A configured AG Grid instance
 *
 * @example
 * // Auto-height grid
 * <PolicyCheckerAgGrid
 *   columnDefs={columns}
 *   rowData={data}
 *   onRowClicked={handleRowClick}
 * />
 *
 * // Fixed height grid
 * <PolicyCheckerAgGrid
 *   columnDefs={columns}
 *   rowData={data}
 *   onRowClicked={handleRowClick}
 *   domLayout="normal"
 * />
 */
const PolicyCheckerAgGrid = ({ gridRef, columnDefs, rowData, onRowClicked, domLayout = "normal" }) => {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      width: 100,
      editable: false,
      suppressMovable: true,
      floatingFilter: false,
      tooltipComponentParams: { color: "#ececec" },
      cellStyle: { textAlign: "left" },
    };
  }, []);

  return (
    <AgGridReact
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={rowData}
      pagination={false}
      defaultColDef={defaultColDef}
      tooltipShowDelay={0}
      onRowClicked={onRowClicked}
      domLayout={domLayout}
      getRowStyle={(params) => {
        return params.node.rowIndex % 2 === 0 ? agGridStyles.evenRow : agGridStyles.oddRow;
      }}
    />
  );
};

PolicyCheckerAgGrid.propTypes = {
  gridRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  onRowClicked: PropTypes.func.isRequired,
  domLayout: PropTypes.string,
};

export default PolicyCheckerAgGrid;
