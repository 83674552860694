import { Link, Box, Typography } from "@mui/material";
import Logo from "../assets/frp-logo.svg";
import PropTypes from "prop-types";

const LogoContainer = ({ logoStyles }) => {
  return (
    <Link href="/" style={logoStyles.headerLink}>
      <Box component="img" src={Logo} alt="frp logo" sx={logoStyles.logoImage} />
      <Typography variant="h6" sx={logoStyles.logo} role="heading">
        Policy checker
      </Typography>
    </Link>
  );
};
LogoContainer.propTypes = {
  logoStyles: PropTypes.shape({
    headerLink: PropTypes.object,
    logoImage: PropTypes.object,
    logo: PropTypes.object,
  }).isRequired,
};
export default LogoContainer;
