const mockData = {
  status: "success",
  message: "Issuers config records fetched successfully.",
  data: [
    {
      issuer_id: 1,
      issuer_name: "AMERICAN BANKERS INSURANCE COMPANY OF FLORIDA",
    },
    {
      issuer_id: 2,
      issuer_name: "AMERICAN ZURICH INSURANCE COMPANY",
    },
    {
      issuer_id: 3,
      issuer_name: "ASSOCIATED INDUSTRIES INSURANCE COMPANY INC",
    },
    {
      issuer_id: 4,
      issuer_name: "AUTO OWNERS INSURANCE COMPANY",
    },
    {
      issuer_id: 5,
      issuer_name: "CITIZENS PROPERTY INSURANCE CORPORATION",
    },
    {
      issuer_id: 6,
      issuer_name: "EMPLOYERS PREFERRED INSURANCE COMPANY",
    },
    {
      issuer_id: 7,
      issuer_name: "FEDERAL INSURANCE COMPANY",
    },
    {
      issuer_id: 8,
      issuer_name: "FRONTLINE INSURANCE UNLIMITED COMPANY",
    },
    {
      issuer_id: 9,
      issuer_name: "GUIDEONE ELITE INSURANCE COMPANY",
    },
    {
      issuer_id: 10,
      issuer_name: "GUIDEONE INSURANCE COMPANY",
    },
    {
      issuer_id: 11,
      issuer_name: "HARTFORD CASUALTY INSURANCE COMPANY",
    },
    {
      issuer_id: 12,
      issuer_name: "HARTFORD UNDERWRITERS INSURANCE COMPANY",
    },
    {
      issuer_id: 13,
      issuer_name: "MAIN STREET AMERICA PROTECTION INSURANCE COMPANY",
    },
    {
      issuer_id: 14,
      issuer_name: "OHIO SECURITY INSURANCE COMPANY",
    },
    {
      issuer_id: 15,
      issuer_name: "PHILADELPHIA INDEMNITY INSURANCE COMPANY",
    },
    {
      issuer_id: 16,
      issuer_name: "PROGRESSIVE EXPRESS INSURANCE COMPANY",
    },
    {
      issuer_id: 17,
      issuer_name: "SOUTHERN OWNERS INSURANCE COMPANY",
    },
    {
      issuer_id: 18,
      issuer_name: "TECHNOLOGY INSURANCE COMPANY INC",
    },
    {
      issuer_id: 19,
      issuer_name: "TRAVELERS CASUALTY INSURANCE COMPANY OF AMERICA",
    },
    {
      issuer_id: 20,
      issuer_name: "UNITED STATES LIABILITY INSURANCE COMPANY",
    },
    {
      issuer_id: 21,
      issuer_name: "WRIGHT NATIONAL FLOOD INSURANCE COMPANY",
    },
    {
      issuer_id: 22,
      issuer_name: "ZENITH INSURANCE COMPANY",
    },
  ],
};

export default mockData;
