import { useMemo } from "react";
import DocumentLinkRenderer from "../../ag-grid/cellRenderer/DocumentLinkRenderer";
import dateFormatter from "../../ag-grid/valueFormatter/dateFormatter";

/**
 * Custom hook that provides column definitions for the Supporting Documents grid.
 *
 * This hook defines columns for displaying document information and includes
 * functionality for viewing documents through a custom link renderer.
 *
 * @param {Function} setSelectedDocument - Callback function to set the selected document
 *                                        when a document link is clicked
 * @param {Function} setIsPdfVisible - Callback function to control the visibility of
 *                                    the PDF viewer
 *
 * @returns {Array} An array of column definition objects for AG Grid with the following columns:
 *   - Document type: Type/description of the document
 *   - Document: Link to view the document with custom renderer
 *   - Owner: Owner of the document
 *   - Date: Upload date with custom date formatting
 *
 * @example
 * const [selectedDoc, setSelectedDoc] = useState(null);
 * const [isPdfVisible, setIsPdfVisible] = useState(false);
 * const columnDefs = useSupportingDocsColumnDefs(setSelectedDoc, setIsPdfVisible);
 * <AgGridReact columnDefs={columnDefs} />
 */
export const useSupportingDocsColumnDefs = (setSelectedDocument, setIsPdfVisible) => {
  return useMemo(
    () => [
      { headerName: "Document type", field: "fileDescription" },
      {
        headerName: "Document",
        field: "fileUrl",
        cellRenderer: DocumentLinkRenderer,
        cellRendererParams: {
          onClick: (data) => {
            setSelectedDocument(data);
            setIsPdfVisible(true);
          },
        },
      },
      { headerName: "Owner", field: "owner" },
      { headerName: "Date", field: "uploadDate", valueFormatter: dateFormatter },
    ],
    [setSelectedDocument, setIsPdfVisible]
  );
};
export default useSupportingDocsColumnDefs;
