import { useMemo } from "react";

const useComparisonsRowData = (data) => {
  return useMemo(() => {
    if (!data?.comparisons) return [];

    return data.comparisons.map((comparison) => {
      const { comparison_name, comparison_id, first_file_name, second_file_name, comparison_status } = comparison;
      return {
        comparisonId: comparison_id.toString(),
        comparisonName: comparison_name,
        comparisonStatus: comparison_status,
        firstFileName: first_file_name,
        secondFileName: second_file_name,
      };
    });
  }, [data]);
};

export default useComparisonsRowData;
