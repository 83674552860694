import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import { headerStyles } from "../styles/layoutStyles";
import ButtonContainer from "../components/ButtonContainer";
import LogoContainer from "../components/LogoContainer";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../components/SignInButton";
import { SignOutButton } from "../components/SignOutButton";

const Header = () => {
  const isAuthenticated = useIsAuthenticated();

  const buttons = [];

  return (
    <AppBar position="static" sx={headerStyles.appBar}>
      <Toolbar sx={headerStyles.toolbar}>
        <LogoContainer logoStyles={headerStyles.logoStyles} />

        <ButtonContainer buttons={buttons} buttonContainerStyles={headerStyles.buttonContainerStyles} />
        {isAuthenticated ? <SignOutButton /> : <SignInButton />}
      </Toolbar>
    </AppBar>
  );
};
export default Header;
