import { styled } from "@mui/material/styles";
import Tab, { tabClasses } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Link } from "react-router-dom";
import useRouteMatch from "../customHooks/useRouteMatch";

const TabItem = styled(Tab)(({ theme }) => ({
  opacity: 1,
  overflow: "initial",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderTopRightRadius: theme.spacing(1),
  borderColor: (theme.vars || theme).palette.grey[500],
  borderWidth: "1px",
  borderStyle: "solid",
  marginRight: "10px",
  color: (theme.vars || theme).palette.text.primary,
  backgroundColor: (theme.vars || theme).palette.white,
  transition: "0.2s",
  zIndex: 2,
  marginTop: theme.spacing(0.5),
  textTransform: "initial",
  [theme.breakpoints.up("md")]: {
    minWidth: 160,
  },
  "&:before": {
    transition: "0.2s",
  },
  [`& + .${tabClasses.selected}::before`]: {
    opacity: 0,
  },
  "&:hover": {
    [`&:not(.${tabClasses.selected})`]: {
      backgroundColor: "rgba(0 0 0 / 0.2)",
    },
    "&::before": {
      opacity: 0,
    },
    [`& + .${tabClasses.root}::before`]: {
      opacity: 0,
    },
  },
  [`&.${tabClasses.selected}`]: {
    color: (theme.vars || theme).palette.text.primary,
    borderColor: "#6BA1B9",
    textDecoration: "underline",
  },
  [`&.${tabClasses.selected} + .${tabClasses.root}`]: {
    zIndex: 1,
  },
  [`&.${tabClasses.selected} + .${tabClasses.root}::before`]: {
    opacity: 0,
  },
}));

export default function TabButtons() {
  const routes = [
    {
      route: "/",
      label: "Active",
    },
    {
      route: "/history",
      label: "History",
    },
  ];

  const routeMatch = useRouteMatch(routes.map((i) => i.route).reverse());
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab}>
      {routes.map(({ route, label }) => (
        <TabItem key={route} value={route} label={label} component={Link} to={route} />
      ))}
    </Tabs>
  );
}
