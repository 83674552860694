import { useMemo } from "react";
import OverrideResultsButtonRenderer from "../../ag-grid/cellRenderer/overrideButtonRenderer";
import ResultStatusCellRenderer from "../../ag-grid/cellRenderer/ResultStatusCellRenderer";

/**
 * Custom hook that provides column definitions for the Results Page grid.
 *
 * This hook defines columns for displaying checkpoint results and includes
 * functionality for overriding results through a custom button renderer.
 *
 * @param {Function} setOverrideData - Callback function to set the override data when
 *                                    the override button is clicked
 *
 * @returns {Array} An array of column definition objects for AG Grid with the following columns:
 *   - Checkpoint Name: Name of the checkpoint being evaluated
 *   - Result: Status of the checkpoint with custom status renderer
 *   - Override Results: Button column with custom renderer for overriding results
 *
 * @example
 * const [overrideData, setOverrideData] = useState(null);
 * const columnDefs = useResultsPageColumnDefs(setOverrideData);
 * <AgGridReact columnDefs={columnDefs} />
 */
const useResultsPageColumnDefs = (setOverrideData) => {
  return useMemo(
    () => [
      {
        headerName: "Checkpoint Name",
        field: "checkpoint_name",
      },
      {
        headerName: "Result",
        field: "reviewed_result",
        cellRenderer: ResultStatusCellRenderer,
        maxWidth: 150,
      },
      {
        headerName: "1st Checkpoint",
        field: "first_file_checkpoint_value",
        tooltipValueGetter: (params) => params.value,
      },
      {
        headerName: "2nd Checkpoint",
        field: "second_file_checkpoint_value",
        tooltipValueGetter: (params) => params.value,
      },
      {
        headerName: "Override Results",
        field: "overrideResults",
        cellRenderer: OverrideResultsButtonRenderer,
        cellRendererParams: {
          onClick: (data) => {
            setOverrideData(data);
          },
        },
      },
      {
        headerName: "Notes",
        field: "review_notes",
        tooltipValueGetter: (params) => params.value,
      },
    ],
    []
  );
};

export default useResultsPageColumnDefs;
