import { Button } from "@mui/material";
import PropTypes from "prop-types";

const CustomButton = ({ onClick, label, buttonStyle }) => {
  return (
    <Button onClick={onClick} color="inherit" sx={buttonStyle}>
      {label}
    </Button>
  );
};
CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  buttonStyle: PropTypes.object,
};
export default CustomButton;
