import { useMemo } from "react";

const useActivitiesRowData = (data) => {
  return useMemo(() => {
    if (!data?.logs) return [];

    return data.logs.map((activity) => {
      const baseActivity = {
        activity: activity.status,
        date: activity.status_change_date,
        owner: activity.owner,
      };

      if (activity.activity === "Documents uploaded") {
        return {
          ...baseActivity,
          jobFiles: data.jobFiles || [],
        };
      }

      return baseActivity;
    });
  }, [data]);
};

export default useActivitiesRowData;
