const mockData = (jobId) => {
  return {
    jobId,
    files: [
      {
        file_id: 457,
        job_id: jobId,
        line_of_business: "General Liability",
        file_location:
          "https://safrpeusdevpolicy1.blob.core.windows.net/rawfiles/317/Armistead Ellis Jr PA LLC - 23-24 CGL Policy.pdf",
        file_name: "Armistead Ellis Jr PA LLC - 23-24 CGL Policy.pdf",
        file_type: "application/pdf",
        file_size: 9498952,
        scenario_file_id: 1,
        uploaded_at: "2024-12-03T09:52:57.523683+00:00",
        uploaded_by: "qwertyuio",
        file_description: "Old File",
        client: "ertyuiop",
        client_code: "456789",
        user_submitted: "qwertyuio",
      },
      {
        file_id: 458,
        job_id: jobId,
        line_of_business: "General Liability",
        file_location:
          "https://safrpeusdevpolicy1.blob.core.windows.net/rawfiles/317/Armistead Ellis Jr PA LLC - 24-25 CGL Policy.pdf",
        file_name: "Armistead Ellis Jr PA LLC - 24-25 CGL Policy.pdf",
        file_type: "application/pdf",
        file_size: 5363894,
        scenario_file_id: 2,
        uploaded_at: "2024-12-03T09:52:57.717349+00:00",
        uploaded_by: "qwertyuio",
        file_description: "New File",
        client: "ertyuiop",
        client_code: "456789",
        user_submitted: "qwertyuio",
      },
    ],
    comparisons: [
      {
        comparison_id: 58,
        comparison_name: "Policy vs Binder",
        first_file_name: "2324 CGL - Policy.pdf",
        second_file_name: "2425 CGL - Policy.pdf",
      },
      {
        comparison_id: 59,
        comparison_name: "Policy vs Quote",
        first_file_name: "2324 CGL - Policy.pdf",
        second_file_name: "23-24 General Liability Policy.pdf",
      },
      {
        comparison_id: 60,
        comparison_name: "Policy vs Application",
        first_file_name: "2324 CGL - Policy.pdf",
        second_file_name: "24-25 General Liability Policy.pdf",
      },
      {
        comparison_id: 61,
        comparison_name: "Binder vs Quote",
        first_file_name: "2425 CGL - Policy.pdf",
        second_file_name: "23-24 General Liability Policy.pdf",
      },
      {
        comparison_id: 62,
        comparison_name: "Binder vs Application",
        first_file_name: "2425 CGL - Policy.pdf",
        second_file_name: "24-25 General Liability Policy.pdf",
      },
      {
        comparison_id: 63,
        comparison_name: "Quote vs Application",
        first_file_name: "23-24 General Liability Policy.pdf",
        second_file_name: "24-25 General Liability Policy.pdf",
      },
    ],
    logs: [
      {
        log_id: 71,
        job_id: jobId,
        status: "New",
        status_change_date: "2024-12-04T10:29:53.927933+00:00",
      },
    ],
  };
};

export default mockData;
