import { SignInButton } from "../components/SignInButton";
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Login() {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!instance.getActiveAccount()) {
      instance.loginRedirect(loginRequest);
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <div>This application needs you to be logged in to use it.</div>
      <SignInButton />
    </div>
  );
}

export default Login;
