export const agGridStyles = {
  headerCell: {
    backgroundColor: "#4CAF50",
    color: "white",
    fontWeight: "bold",
  },

  evenRow: {
    backgroundColor: "#ffffff",
  },

  oddRow: {
    backgroundColor: "#D9D9D9",
  },
};
