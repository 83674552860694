import PropTypes from "prop-types";

const DocumentLinkRenderer = ({ data, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(data);
    }
  };

  return (
    <span onClick={handleClick} style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
      {data.fileName}
    </span>
  );
};

DocumentLinkRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
export default DocumentLinkRenderer;
