import React from "react";
import CustomButton from "./CustomButton";
import PdfViewer from "./PdfViewer";
import jobDetailsPageStyles from "../styles/jobDetailsPageStyles";
import PropTypes from "prop-types";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "../styles/styleOverride.css";

/**
 * A container component that renders a PDF viewer with a close button overlay.
 * This component combines the PdfViewer component with a close button in a modal-like layout.
 *
 * Features:
 * - Full-screen overlay for PDF viewing
 * - PDF content display with width-based zoom
 * - Close button for dismissing the viewer
 * - Styled container with proper positioning
 *
 * @component
 * @param {Object} props
 * @param {Object} props.pdfContent - Object containing the PDF data
 * @param {string} props.pdfContent.file_content - Base64 encoded PDF content to be displayed
 * @param {Function} props.onClose - Callback function to handle closing the PDF viewer
 *
 * @example
 * <PdfViewerAndButtonContainer
 *   pdfContent={{ file_content: "base64EncodedPdfData" }}
 *   onClose={() => handleClose()}
 * />
 */
const PdfViewerAndButtonContainer = ({ pdfContent, onClose }) => {
  console.log("pdfContent", pdfContent);
  return (
    <div style={jobDetailsPageStyles.overlay}>
      <div style={jobDetailsPageStyles.pdfViewer}>
        <PdfViewer pdfUrl={pdfContent.file_content} zoomLevel="width" />
      </div>
      <CustomButton onClick={onClose} buttonStyle={jobDetailsPageStyles.closeButton} label="Close" />
    </div>
  );
};

PdfViewerAndButtonContainer.propTypes = {
  pdfContent: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PdfViewerAndButtonContainer;
