import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const OverrideResultsButtonRenderer = ({ data, onClick }) => {
  return (
    <Button sx={{ textTransform: "none" }} onClick={onClick.bind(this, data)}>
      Override
    </Button>
  );
};

OverrideResultsButtonRenderer.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default OverrideResultsButtonRenderer;
