import mockData from "./mocks/scenario-data";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

export default async function fetchScenarios() {
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    return Promise.resolve(mockData);
  }
  const response = await fetch(`${REACT_APP_BACKEND_URL}api/scenarios?`);
  return await response.json();
}
