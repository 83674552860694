export const headerStyles = {
  appBar: {
    backgroundColor: "#00203D",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  logoStyles: {
    logoImage: {
      height: 40,
    },
    logo: {
      color: "white",
      textTransform: "none",
      paddingLeft: "5px",
    },
    headerLink: {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },
  },
  searchContainerStyles: {
    container: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
    },
    textField: {
      marginRight: 1,
      backgroundColor: "white",
      border: "none",
      borderRadius: "5px",
    },
    iconButton: {
      backgroundColor: "white",
      borderRadius: "5px",
      padding: "4px 8px",
      "&:hover": { backgroundColor: "#f0f0f0" },
    },
    searchIcon: { color: "#00220D" },
  },
  buttonContainerStyles: {
    button: {
      marginRight: 2,
      textTransform: "none",
    },
  },
};
