/* global Intl */
import moment from "moment-timezone";

const simpleDateFormatter = (params, format = "MM-DD-yyyy") => {
  return params && params.value ? moment(params.value).format(format) : "";
};

const detailedDateFormatter = (params) => {
  if (!params || !params.value) return "";
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log("timezone", localTimeZone, params.value);
  const decimalPart = params.value.toString().split(".")[1].split("+")[0];

  return moment.utc(params.value).tz(localTimeZone).format("MM-DD-yyyy h:mm:ss.||| Z").replace("|||", decimalPart);
};

export { simpleDateFormatter as default, detailedDateFormatter };
