const mockData = {
  status: "success",
  message: "scenario config records fetched successfully.",
  data: [
    {
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      scenario_file_id: 1,
      file_description: "Old File",
      optional: false,
    },
    {
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      scenario_file_id: 2,
      file_description: "New File",
      optional: false,
    },
    {
      scenario_id: 2,
      scenario_name: "Non-standard (Wholesaler / Agency Bill)",
      scenario_file_id: 3,
      file_description: "Policy",
      optional: false,
    },
    {
      scenario_id: 2,
      scenario_name: "Non-standard (Wholesaler / Agency Bill)",
      scenario_file_id: 4,
      file_description: "Binder",
      optional: false,
    },
    {
      scenario_id: 2,
      scenario_name: "Non-standard (Wholesaler / Agency Bill)",
      scenario_file_id: 5,
      file_description: "Quote",
      optional: false,
    },
    {
      scenario_id: 2,
      scenario_name: "Non-standard (Wholesaler / Agency Bill)",
      scenario_file_id: 6,
      file_description: "Application",
      optional: false,
    },
    {
      scenario_id: 3,
      scenario_name: "Marketing",
      scenario_file_id: 7,
      file_description: "Policy",
      optional: false,
    },
    {
      scenario_id: 3,
      scenario_name: "Marketing",
      scenario_file_id: 8,
      file_description: "Binder",
      optional: false,
    },
    {
      scenario_id: 3,
      scenario_name: "Marketing",
      scenario_file_id: 9,
      file_description: "Quote",
      optional: false,
    },
    {
      scenario_id: 3,
      scenario_name: "Marketing",
      scenario_file_id: 10,
      file_description: "Application",
      optional: false,
    },
  ],
};

export default mockData;
