import { useRef, useState } from "react";
import { Button, ClickAwayListener, MenuItem, MenuList, Popper, Grow, Paper } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import useActionButtonPopulator from "../customHooks/useActionButtonPopulator";
import { useNavigate } from "react-router-dom";

/**
 * ActionButton is a dropdown menu component that allows users to create new checks
 * based on available scenarios.
 *
 * The component displays a button labeled "Create New Check" which, when clicked,
 * opens a dropdown menu containing a list of available scenarios. Selecting a scenario
 * navigates the user to a new job creation page for that specific scenario.
 *
 * Features:
 * - Displays a styled button with dropdown functionality
 * - Loads scenarios dynamically using useActionButtonPopulator hook
 * - Handles loading and error states
 * - Implements click-away functionality to close the dropdown
 * - Provides smooth grow animation for the dropdown menu
 *
 * @component
 * @returns {JSX.Element} A button with dropdown menu functionality
 */
const ActionButton = () => {
  const actions = useActionButtonPopulator();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    if (anchorRef.current) setOpen(false);
  };

  if (actions.isLoading) {
    return <>Loading</>;
  }
  if (actions.isError || actions.data?.data?.length < 1) {
    return <Button disabled>Error</Button>;
  }

  const onClick = (scenarioId, event) => {
    event.preventDefault();
    setOpen(false);
    navigate(`/newJob/${scenarioId}`);
  };

  return (
    <>
      <Button
        onClick={onToggle}
        ref={anchorRef}
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup={"menu"}
        variant={"outlined"}
        endIcon={<ExpandMore />}
        sx={{
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "12px",
          color: "#000000",
          border: "1px solid #e5e0e0",
          textTransform: "none",
          padding: "12px",
          borderRadius: 0,
          width: "175px",
        }}
      >
        Create New Check
      </Button>
      <Popper sx={{ zIndex: 3 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList
                  id="action-button-menu"
                  autoFocusItem
                  dense
                  sx={{
                    padding: 0,
                    borderRadius: 0,
                    width: "175px",
                  }}
                >
                  {actions.data.data.map(({ scenario_id, scenario_name }) => (
                    <MenuItem
                      key={scenario_id}
                      onClick={onClick.bind(this, scenario_id)}
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "12px",
                        color: "#000000",
                        border: "1px solid #e5e0e0",
                        textTransform: "none",
                        padding: "12px",
                        borderRadius: 0,
                        width: "175px",
                        whiteSpace: "normal",
                        textAlign: "left",
                      }}
                    >
                      {scenario_name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ActionButton;
