const mockData = {
  status: "success",
  message: "Comparison results fetched successfully for 50",
  data: [
    {
      results_id: 814,
      comparison_id: 50,
      checkpoint_name: "Retroactive Date",
      first_file_checkpoint_value: null,
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value: null,
      second_file_checkpoint_page_no: null,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 815,
      comparison_id: 50,
      checkpoint_name: "Location Address",
      first_file_checkpoint_value: "233 San Juan Ave, Daytona Beach, FL 32114-3227",
      first_file_checkpoint_page_no: 7,
      second_file_checkpoint_value: "233 San Juan Ave, Daytona Beach, FL 32114-3227",
      second_file_checkpoint_page_no: 7,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 816,
      comparison_id: 50,
      checkpoint_name: "Classifications",
      first_file_checkpoint_value:
        '{"{\\"value\\": \\"Commercial General Liability Plus Endorsement\\", \\"page_number\\": \\"7\\"}","{\\"value\\": \\"Included At 7.4% Of The Premises Operation Premium\\", \\"page_number\\": \\"7\\"}","{\\"value\\": \\"Dwellings - One-Family (Lessor\'s Risk Only)\\", \\"page_number\\": \\"7\\"}"}',
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value:
        '{"{\\"value\\": \\"Commercial General Liability Plus Endorsement\\", \\"page_number\\": \\"7\\"}","{\\"value\\": \\"Included At 7.4% Of The Premises Operation Premium\\", \\"page_number\\": \\"7\\"}","{\\"value\\": \\"Dwellings - One-Family (Lessor\'s Risk Only)\\", \\"page_number\\": \\"7\\"}"}',
      second_file_checkpoint_page_no: null,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 817,
      comparison_id: 50,
      checkpoint_name: "Additional Coverage.HNOA (Hired and Non owned Auto)",
      first_file_checkpoint_value: "$1,000,000 Each Occurrence",
      first_file_checkpoint_page_no: 6,
      second_file_checkpoint_value: "$1,000,000 Each Occurrence",
      second_file_checkpoint_page_no: 6,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 818,
      comparison_id: 50,
      checkpoint_name: "Additional Interest.Loan number",
      first_file_checkpoint_value: null,
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value: null,
      second_file_checkpoint_page_no: null,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 819,
      comparison_id: 50,
      checkpoint_name: "Additional Interest.Additional Interest Address",
      first_file_checkpoint_value: null,
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value: null,
      second_file_checkpoint_page_no: null,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 820,
      comparison_id: 50,
      checkpoint_name: "Additional Interest.Additional Interest Name",
      first_file_checkpoint_value: null,
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value: null,
      second_file_checkpoint_page_no: null,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 821,
      comparison_id: 50,
      checkpoint_name: "Code",
      first_file_checkpoint_value:
        '{"{\\"value\\": \\"00501\\", \\"page_number\\": \\"7\\"}","{\\"value\\": \\"63010\\", \\"page_number\\": \\"7\\"}"}',
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value:
        '{"{\\"value\\": \\"00501\\", \\"page_number\\": \\"7\\"}","{\\"value\\": \\"63010\\", \\"page_number\\": \\"7\\"}"}',
      second_file_checkpoint_page_no: null,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 822,
      comparison_id: 50,
      checkpoint_name: "Insured Name",
      first_file_checkpoint_value: "ELLIS ARMISTEAD W JR",
      first_file_checkpoint_page_no: 5,
      second_file_checkpoint_value: "ELLIS ARMISTEAD W JR",
      second_file_checkpoint_page_no: 5,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 823,
      comparison_id: 50,
      checkpoint_name: "Mailing Address",
      first_file_checkpoint_value: "PO BOX 127, DAYTONA BEACH FL 32115-0127",
      first_file_checkpoint_page_no: 5,
      second_file_checkpoint_value: "PO BOX 127, DAYTONA BEACH FL 32115-0127",
      second_file_checkpoint_page_no: 5,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 824,
      comparison_id: 50,
      checkpoint_name: "Forms",
      first_file_checkpoint_value: '{"{\\"value\\": \\"55156 (07-12)\\", \\"page_number\\": \\"5\\"}"}',
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value:
        '{"{\\"value\\": \\"CG2144 (04-17), 59350 (01-15), 55146 (06-04), IL0017 (11-85), CG0220 (03-12), IL0021 (07-02), 55881 (12-17), CG2106 (05-14), CG2167 (12-04), 59325 (12-19), CG0001 (04-13), 55513 (05-17), 55719 (05-17), CG2109 (06-15), 55029 (05-17), CG2196 (03-05), CG2132 (05-09), CG2147 (12-07), 55885 (05-17), 65034 (06-22)\\", \\"page_number\\": \\"7\\"}"}',
      second_file_checkpoint_page_no: null,
      checkpoint_result: false,
      reviewed_result: false,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 825,
      comparison_id: 50,
      checkpoint_name: "Policy Term",
      first_file_checkpoint_value: "08-14-2023 to 08-14-2024",
      first_file_checkpoint_page_no: 5,
      second_file_checkpoint_value: "08-14-2024 to 08-14-2025",
      second_file_checkpoint_page_no: 5,
      checkpoint_result: false,
      reviewed_result: false,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 826,
      comparison_id: 50,
      checkpoint_name: "Total",
      first_file_checkpoint_value: "$436.80",
      first_file_checkpoint_page_no: 5,
      second_file_checkpoint_value: "$437.15",
      second_file_checkpoint_page_no: 5,
      checkpoint_result: false,
      reviewed_result: false,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 827,
      comparison_id: 50,
      checkpoint_name: "Policy Number",
      first_file_checkpoint_value: "122382-72064309",
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value: "122382-72064309",
      second_file_checkpoint_page_no: null,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 828,
      comparison_id: 50,
      checkpoint_name: "Premium Basis",
      first_file_checkpoint_value:
        '{"{\\"value\\": \\"Prem/Op Prem\\", \\"page_number\\": \\"7\\"}","{\\"value\\": \\"Dwellings\\", \\"page_number\\": \\"7\\"}"}',
      first_file_checkpoint_page_no: null,
      second_file_checkpoint_value:
        '{"{\\"value\\": \\"Prem/Op Prem\\", \\"page_number\\": \\"7\\"}","{\\"value\\": \\"Dwellings\\", \\"page_number\\": \\"7\\"}"}',
      second_file_checkpoint_page_no: null,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 829,
      comparison_id: 50,
      checkpoint_name: "Main Coverages & Deductible.Medical Expense",
      first_file_checkpoint_value: "$10,000 Any One Person",
      first_file_checkpoint_page_no: 6,
      second_file_checkpoint_value: "$10,000 Any One Person",
      second_file_checkpoint_page_no: 6,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 830,
      comparison_id: 50,
      checkpoint_name: "Main Coverages & Deductible.Each Occurrence",
      first_file_checkpoint_value: "$1,000,000",
      first_file_checkpoint_page_no: 6,
      second_file_checkpoint_value: "$1,000,000",
      second_file_checkpoint_page_no: 6,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 831,
      comparison_id: 50,
      checkpoint_name: "Main Coverages & Deductible.Products/Completed Operations Aggregate",
      first_file_checkpoint_value: "$2,000,000",
      first_file_checkpoint_page_no: 6,
      second_file_checkpoint_value: "$2,000,000",
      second_file_checkpoint_page_no: 6,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 832,
      comparison_id: 50,
      checkpoint_name: "Main Coverages & Deductible.Fire Damage/Damage to Premises Rented to You",
      first_file_checkpoint_value: "$300,000 Any One Premises",
      first_file_checkpoint_page_no: 6,
      second_file_checkpoint_value: "$300,000 Any One Premises",
      second_file_checkpoint_page_no: 6,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 833,
      comparison_id: 50,
      checkpoint_name: "Main Coverages & Deductible.General Aggregate",
      first_file_checkpoint_value: "$2,000,000",
      first_file_checkpoint_page_no: 6,
      second_file_checkpoint_value: "$2,000,000",
      second_file_checkpoint_page_no: 6,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
    {
      results_id: 834,
      comparison_id: 50,
      checkpoint_name: "Main Coverages & Deductible.Personal & Advertising Injury",
      first_file_checkpoint_value: "$1,000,000",
      first_file_checkpoint_page_no: 6,
      second_file_checkpoint_value: "$1,000,000",
      second_file_checkpoint_page_no: 6,
      checkpoint_result: true,
      reviewed_result: true,
      review_user: "",
      review_notes: "",
    },
  ],
};

export default mockData;
