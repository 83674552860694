import { useQuery } from "@tanstack/react-query";
import fetchHistoryChecks from "../services/get-historyChecks-api";

const useHistoryChecks = () => {
  return useQuery({
    queryKey: ["historyJobs"],
    queryFn: fetchHistoryChecks,
  });
};
export default useHistoryChecks;
