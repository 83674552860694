import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
const ErrorState = ({ errorMessage }) => (
  <Box sx={{ margin: "20px", textAlign: "center" }}>
    <Typography>{errorMessage}</Typography>
  </Box>
);
ErrorState.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};
export default ErrorState;
