import PdfViewer from "../components/PdfViewer";
import GridContainer from "../components/GridContainer";
import InformationContainer from "../components/InformationContainer";
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useResultsPageColumnDefs from "../customHooks/columnDefs/useResultsPageColumnDefs";
import useResultsById from "../customHooks/useResults";
import { useParams } from "react-router-dom";
import useFilesByComparison from "../customHooks/useFilesByComparison";
import useJobDetailsData from "../customHooks/useJobDetailsData";
import OverrideModal from "../components/OverrideModal";
import { useIsAuthenticated } from "@azure/msal-react";
import useComparisonCompleteMutator from "../customHooks/useComparisonCompleteMutator";
import useEnvVars from "../customHooks/useEnvVars";
import "../styles/resultspage.css";

/**
 * Results component displays the comparison results between two PDF documents.
 *
 * This component provides a comprehensive view of policy comparison results including:
 * - Information about the job and client
 * - Side-by-side PDF viewers for both documents with highlighted differences
 * - A breakdown grid showing detailed comparison results with sorted data:
 *   - Primary sort by page number (null values appear last)
 *   - Secondary sort by checkpoint name (alphabetically) when page numbers are equal
 * - Override functionality for managing discrepancies
 * - Completion functionality to mark the comparison as complete
 *
 * The component uses URL parameters to fetch specific job and comparison data:
 * @param {string} jobId - URL parameter for the job identifier
 * @param {string} comparisonId - URL parameter for the comparison identifier
 *
 * Features:
 * - Displays client and job information in an information panel
 * - Shows two PDF viewers side by side with synchronized navigation
 * - Highlights relevant text in PDFs based on comparison results
 * - Provides a detailed breakdown grid of all comparison points
 * - Supports override functionality for managing discrepancies
 * - Includes a complete button for finalizing and marking the comparison as complete
 * - Implements smart sorting:
 *   - Results are ordered by page number in ascending order
 *   - Results with null page numbers are displayed last
 *   - When page numbers match, results are sorted alphabetically by checkpoint name
 *
 * State Management:
 * - Manages selected result state for PDF highlighting
 * - Handles override modal state for discrepancy management
 * - Manages comparison completion state through mutation
 *
 * @returns {JSX.Element} The rendered Results component
 */
const Results = () => {
  const { jobId, comparisonId } = useParams();
  const { data: results } = useResultsById(comparisonId);
  const { data: files } = useFilesByComparison(comparisonId);
  const { data: jobDetails } = useJobDetailsData(jobId);
  const [selectedResult, setSelectedResult] = useState(null);
  const [overrideData, setOverrideData] = useState(null);
  const resultsPageColumnDefs = useResultsPageColumnDefs(setOverrideData);
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const completeMutation = useComparisonCompleteMutator();
  const { REACT_APP_USE_AUTH_BLOCK } = useEnvVars();

  useEffect(() => {
    if (results?.length > 0 && files?.length >= 2) {
      const firstResult = results[0];
      if (firstResult.first_file_checkpoint_value && firstResult.second_file_checkpoint_value) {
        setSelectedResult(firstResult);
      }
    }
  }, [results, files]);

  useEffect(() => {
    if (REACT_APP_USE_AUTH_BLOCK !== "false" && !isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated, REACT_APP_USE_AUTH_BLOCK]);

  if (REACT_APP_USE_AUTH_BLOCK !== "false" && !isAuthenticated) {
    return <div>Invalid access</div>;
  }

  const handleRowClick = (e) => {
    const result = e.data;
    if (!files[0] || !files[1]) return;
    if (!result.first_file_checkpoint_value || !result.second_file_checkpoint_value) return;
    setSelectedResult(result);
  };
  if (!files || !results) {
    return <div>Loading...</div>;
  }

  const thisComparison = jobDetails?.comparisons?.find((c) => c.comparison_id.toString() === comparisonId);

  return (
    <div className="results-container">
      <div className="information-container">
        {results && results.length > 0 && (
          <InformationContainer
            clientName={jobDetails?.files?.[0]?.client || "N/A"}
            clientCode={jobDetails?.files?.[0]?.client_code || "N/A"}
            scenarioName={jobDetails?.scenario_name || "N/A"}
            lineOfBusiness={jobDetails?.files?.[0]?.line_of_business || "N/A"}
            jobId={jobId || "N/A"}
            client={jobDetails?.client_name || "N/A"}
            comparisonId={comparisonId || "N/A"}
            comparisonName={thisComparison?.comparison_name || files?.[0]?.file_name || "N/A"}
          />
        )}
      </div>
      <div className="pdf-container">
        {
          <div key="pdf-container-left" className={`pdf-viewer-container firstDocument_${0}`}>
            <h3>{files[0].file_name}</h3>
            {files[0].file_content && (
              <PdfViewer
                pdfUrl={files[0].file_content}
                searchTerm={selectedResult?.first_file_checkpoint_value}
                targetPages={selectedResult?.first_file_checkpoint_page_no - 1}
                isMatch={selectedResult?.checkpoint_result}
              />
            )}
          </div>
        }
        {
          <div key="pdf-container-right" className={`pdf-viewer-container firstDocument_${1}`}>
            <h3>{files[1].file_name}</h3>
            {files[1].file_content && (
              <PdfViewer
                pdfUrl={files[1].file_content}
                searchTerm={selectedResult?.second_file_checkpoint_value}
                targetPages={selectedResult?.second_file_checkpoint_page_no - 1}
                isMatch={selectedResult?.checkpoint_result}
              />
            )}
          </div>
        }
      </div>
      <GridContainer
        title="Breakdown"
        columnDefs={resultsPageColumnDefs}
        rowData={(results || []).sort((a, b) => {
          // Handle null page numbers (push them to the end)
          if (a.first_file_checkpoint_page_no === null && b.first_file_checkpoint_page_no === null) {
            return a.checkpoint_name.localeCompare(b.checkpoint_name);
          }
          if (a.first_file_checkpoint_page_no === null) return 1;
          if (b.first_file_checkpoint_page_no === null) return -1;

          // Sort by page number
          const pageComparison = a.first_file_checkpoint_page_no - b.first_file_checkpoint_page_no;

          // If pages are equal, sort by checkpoint_name
          return pageComparison !== 0 ? pageComparison : a.checkpoint_name.localeCompare(b.checkpoint_name);
        })}
        onRowClicked={handleRowClick}
        domLayout="normal"
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            completeMutation.mutate(comparisonId, {
              onSuccess: () => navigate(`/jobdetails/${jobId}`),
            });
          }}
          disabled={completeMutation.isLoading}
        >
          Complete
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/jobdetails/${jobId}`);
          }}
        >
          Close
        </Button>
      </div>
      <OverrideModal overrideData={overrideData} setOverrideData={setOverrideData} />
    </div>
  );
};

export default Results;
