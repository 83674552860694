import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InformationContainer from "../components/InformationContainer";
import useJobDetailsData from "../customHooks/useJobDetailsData";
import Loading from "../components/Loading";
import ErrorState from "../components/ErrorState";
import { useParams } from "react-router-dom";
import GridContainer from "../components/GridContainer";
import PdfViewerAndButtonContainer from "../components/PdfViewerAndButtonContainer";
import useActivitiesRowData from "../customHooks/useActivitiesRowData";
import useSupportingDocsRowData from "../customHooks/useSupportingDocsRowData";
import useComparisonsRowData from "../customHooks/useComparisonsRowData";
import { useActivityTrackerColumnDefs } from "../customHooks/columnDefs/useActivityTrackerColumnDefs";
import useSupportingDocsColumnDefs from "../customHooks/columnDefs/useSupportingDocsColumnDefs";
import useComparisonColumnDefs from "../customHooks/columnDefs/useComparisonColumnDefs";
import DocumentLinkRenderer from "../ag-grid/cellRenderer/DocumentLinkRenderer";
import useLoadDocument from "../customHooks/useLoadDocument";
import { useIsAuthenticated } from "@azure/msal-react";
import useEnvVars from "../customHooks/useEnvVars";

/**
 * JobDetails component displays comprehensive information about a specific job in the policy checker system.
 *
 * This component provides a detailed view of a job including:
 * - Client and job information
 * - Supporting documents grid
 * - Comparisons grid
 * - Activity tracking grid
 * - PDF viewer for document preview
 *
 * @component
 *
 * Features:
 * - Displays job metadata in an information panel (client info, scenario, line of business)
 * - Shows supporting documents with interactive document preview
 * - Lists all comparisons associated with the job
 * - Tracks activities and changes through an activity grid
 * - Provides PDF preview functionality for documents
 * - Includes navigation back to the main view
 *
 * State Management:
 * - Manages selected document state for PDF preview
 * - Controls PDF viewer visibility
 * - Handles loading states and error conditions
 *
 * Data Hooks:
 * - useJobDetailsData: Fetches main job information
 * - useSupportingDocsRowData: Processes supporting documents data
 * - useActivitiesRowData: Manages activity tracking data
 * - useComparisonsRowData: Handles comparison data
 * - useLoadDocument: Manages document loading for preview
 *
 * Grid Components:
 * - Supporting documents grid with document preview capability
 * - Comparisons grid showing all policy comparisons
 * - Activity tracker grid displaying job history
 *
 * @returns {JSX.Element} The rendered JobDetails component
 */
const JobDetails = () => {
  const { jobId } = useParams();
  const { data, isLoading, isError } = useJobDetailsData(jobId);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isPdfVisible, setIsPdfVisible] = useState(false);
  const supportingDocsRowData = useSupportingDocsRowData(data);
  const activitiesRowData = useActivitiesRowData(data);
  const comparisonsRowData = useComparisonsRowData(data);
  const supportingDocsColumnDefs = useSupportingDocsColumnDefs(setSelectedDocument, setIsPdfVisible);
  const activityTrackerColumnDefs = useActivityTrackerColumnDefs(setSelectedDocument, setIsPdfVisible);
  const comparisonColumnDefs = useComparisonColumnDefs();
  const documentLoader = useLoadDocument(selectedDocument);
  const navigate = useNavigate();
  const selectedDocumentContent = documentLoader.data;
  const isAuthenticated = useIsAuthenticated();
  const { REACT_APP_USE_AUTH_BLOCK } = useEnvVars();

  useEffect(() => {
    if (REACT_APP_USE_AUTH_BLOCK !== "false" && !isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated, REACT_APP_USE_AUTH_BLOCK]);

  if (isLoading) return <Loading />;
  if (!data || isError) return <ErrorState errorMessage={"Error fetching job details."} />;
  if (REACT_APP_USE_AUTH_BLOCK !== "false" && !isAuthenticated) {
    console.log("JobDetails", REACT_APP_USE_AUTH_BLOCK, isAuthenticated);
    return <div>Invalid access</div>;
  }
  return (
    <Box sx={{ margin: "50px 20px" }}>
      <InformationContainer
        clientName={data?.files?.[0]?.client}
        clientCode={data?.files?.[0]?.client_code}
        scenarioName={data.scenarioName}
        lineOfBusiness={data?.files?.[0]?.line_of_business}
        jobId={jobId}
      />
      <Box sx={{ border: "2px solid #2F94CD" }}>
        <GridContainer
          title="Supporting documents"
          columnDefs={supportingDocsColumnDefs}
          rowData={supportingDocsRowData}
          frameworkComponents={{
            documentLinkRenderer: DocumentLinkRenderer,
          }}
          onRowClicked={() => {}}
          domLayout="autoHeight"
        />
        <GridContainer
          title="Comparisons"
          columnDefs={comparisonColumnDefs}
          rowData={comparisonsRowData}
          onRowClicked={() => {}}
          domLayout="autoHeight"
        />
        <GridContainer
          title="Activity tracker"
          columnDefs={activityTrackerColumnDefs}
          rowData={activitiesRowData}
          frameworkComponents={{
            documentLinkRenderer: DocumentLinkRenderer,
          }}
          onRowClicked={() => {}}
          domLayout="autoHeight"
        />
        {isPdfVisible && selectedDocumentContent && (
          <PdfViewerAndButtonContainer pdfContent={selectedDocumentContent} onClose={() => setIsPdfVisible(false)} />
        )}
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/");
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};
export default JobDetails;
