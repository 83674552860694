// REQUEST: GET ? api/complete-results?comparison_id=72

const mockData = {
  status: "success",
  message: "Results completed successfully.",
  updated_records: {
    status: "success",
    message: "Results updated successfully",
    updated_comparisons: [
      {
        comparison_id: 72,
        comparison_status: "Results Reviewed",
      },
    ],
    updated_jobs: [
      {
        job_id: 277,
        status_id: 4,
      },
    ],
    job_logs: [
      {
        log_id: 432,
        job_id: 277,
        status: "Results Reviewed",
      },
    ],
  },
};

export default mockData;
