const mockData = {
  status: "success",
  message: "Scenarios fetched successfully.",
  data: [
    { scenario_id: 1, scenario_name: "Autorenewal Direct Bill" },
    { scenario_id: 2, scenario_name: "Non-standard (Wholesaler / Agency Bill)" },
    { scenario_id: 3, scenario_name: "Marketing" },
  ],
};

export default mockData;
