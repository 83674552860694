import mockData from "./mocks/file-by-fileid";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

const fetchFilesByFileId = async (fileId) => {
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    return Promise.resolve(mockData);
  }
  const response = await fetch(`${REACT_APP_BACKEND_URL}api/job-files-by-fileid?file_id=${fileId}`);
  const data = await response.json();
  return data.data;
};

export default fetchFilesByFileId;
