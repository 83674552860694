import { Box } from "@mui/material";
import { useGridFilter } from "ag-grid-react";
import { useCallback } from "react";
import useActionButtonPopulator from "../../customHooks/useActionButtonPopulator";
import PropTypes from "prop-types";

const CustomFilter = ({ model, onModelChange, getValue }) => {
  const actions = useActionButtonPopulator();

  const options = [
    { scenario_id: "1", scenario_name: "Auto renewal direct bill" },
    { scenario_id: "2", scenario_name: "No marketing" },
    { scenario_id: "3", scenario_name: "Nonstandard (agency/wholesaler)" },
  ];
  // hard coded options data to be removed once cors issue is fixed
  const scenarios = actions.data?.data || options;
  const valueChanged = useCallback(
    (event) => {
      const { value, checked } = event.target;
      const newModel = checked ? [...(model || []), value] : (model || []).filter((v) => v !== value);
      onModelChange(newModel.length ? newModel : null);
    },
    [model, onModelChange]
  );

  const doesFilterPass = useCallback(
    ({ node }) => {
      const value = getValue(node);
      return model ? model.includes(value) : true;
    },
    [model, getValue]
  );

  useGridFilter({ doesFilterPass });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "120.5px" }}>
      {scenarios.map(({ scenario_name }) => (
        <label
          key={scenario_name}
          style={{ margin: "8px", display: "flex", alignItems: "center", paddingLeft: "10px" }}
        >
          <input
            style={{ marginRight: "8px" }}
            type="checkbox"
            value={scenario_name}
            checked={model ? model.includes(`${scenario_name}`) : false}
            onChange={valueChanged}
          />
          {scenario_name}
        </label>
      ))}
    </Box>
  );
};

CustomFilter.propTypes = {
  model: PropTypes.array.isRequired,
  onModelChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
};

export default CustomFilter;
