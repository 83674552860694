import React, { useEffect, useMemo } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import { searchPlugin } from "@react-pdf-viewer/search";
import { Box, CircularProgress } from "@mui/material";

import PropTypes from "prop-types";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";

/**
 * Converts a base64 encoded PDF string to a Blob object.
 * @param {string} data - The base64 encoded PDF data.
 * @returns {Blob} A Blob object containing the PDF data.
 */
const base64toBlob = (data) => {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: "application/pdf" });
};

/**
 * Tokenizes a search term into individual words.
 * @param {string} searchTerm - The search term to tokenize.
 * @returns {string[]} An array of individual words in the search term.
 */
const tokenizeSearchTerm = (searchTerm) => {
  if (!searchTerm) {
    return [];
  }
  return searchTerm.split(" ").filter(Boolean);
};

/**
 * A React component that renders a PDF viewer with search, navigation, and highlighting capabilities.
 * This component uses the @react-pdf-viewer library to provide a rich PDF viewing experience.
 *
 * Features:
 * - PDF rendering with zoom controls
 * - Page navigation
 * - Text search with highlighting
 * - Automatic page jumping to search results
 *
 * @component
 * @param {Object} props
 * @param {string} props.pdfUrl - Base64 encoded PDF data to be displayed
 * @param {string} [props.searchTerm] - Term to search for within the PDF
 * @param {number} [props.targetPages] - Specific page number to navigate to (0-based index)
 * @param {string} [props.zoomLevel] - Zoom level setting ('width' for page width, otherwise fits to page)
 *
 * @example
 * <PdfViewer
 *   pdfUrl="base64EncodedPdfData"
 *   searchTerm="example"
 *   targetPages={2}
 *   zoomLevel="width"
 * />
 */
const PdfViewer = ({ pdfUrl, searchTerm, targetPages, isMatch }) => {
  const pageNavigationPluginInstance = pageNavigationPlugin({
    enableShortcuts: false,
  });
  const highlightPluginInstance = highlightPlugin({
    trigger: Trigger.None,
  });
  const searchPluginInstance = searchPlugin({
    enableShortcuts: false,
    keyword: tokenizeSearchTerm(searchTerm),
    renderHighlights: React.useCallback(
      (renderProps) => (
        <>
          {renderProps.highlightAreas.map((area, index) => (
            <div
              key={`${area.pageIndex}-${index}`}
              style={{
                ...renderProps.getCssProperties(area),
                position: "absolute",
                backgroundColor: isMatch ? "rgba(0, 255, 0, 0.3)" : "rgba(255,0,0,0.3",
              }}
            ></div>
          ))}
        </>
      ),
      [isMatch]
    ),
  });

  useEffect(() => {
    if (typeof targetPages === "number") {
      searchPluginInstance.setTargetPages((tp) => (tp || {}).pageIndex === targetPages);
      // pageNavigationPluginInstance.jumpToPage(targetPages);
      searchPluginInstance.highlight(tokenizeSearchTerm(searchTerm));
      try {
        searchPluginInstance.jumpToMatch(0);
      } catch (ex) {
        console.error(ex);
      }
    }
  }, [targetPages, searchTerm]);

  const url = useMemo(() => {
    const blob = base64toBlob(pdfUrl);
    return URL.createObjectURL(blob);
  }, [pdfUrl]);

  if (!pdfUrl) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress data-testid="loading-indicator" />
      </Box>
    );
  }

  const plugins = [pageNavigationPluginInstance, highlightPluginInstance, searchPluginInstance];
  return (
    <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
      <Viewer
        fileUrl={url}
        defaultScale={SpecialZoomLevel.PageWidth}
        plugins={plugins}
        initialPage={targetPages || 1}
      />
    </Worker>
  );
};

PdfViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  targetPages: PropTypes.number,
  isMatch: PropTypes.bool,
};

PdfViewer.displayName = "PdfViewer";

export default PdfViewer;
