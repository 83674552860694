import PropTypes from "prop-types";
import { Box } from "@mui/material";
import CustomButton from "../components/CustomButton";

const ButtonContainer = ({ buttons, buttonContainerStyles }) => {
  return (
    <Box>
      {buttons.map((button, index) => (
        <CustomButton
          key={`${button.label}_${index}`}
          onClick={button.onClick}
          label={button.label}
          buttonStyle={buttonContainerStyles.button}
        />
      ))}
    </Box>
  );
};

ButtonContainer.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  buttonContainerStyles: PropTypes.shape({
    button: PropTypes.object,
  }).isRequired,
};

export default ButtonContainer;
