const mockData = {
  status: "success",
  message: "LOB records fetched successfully.",
  data: [
    { lob_id: 1, lob_name: "General Liability" },
    { lob_id: 2, lob_name: "Business Auto" },
    { lob_id: 3, lob_name: "Commercial Property" },
    { lob_id: 4, lob_name: "Business Owners Policy" },
    { lob_id: 5, lob_name: "Package Policy" },
    { lob_id: 6, lob_name: "Workers Compensation" },
    { lob_id: 7, lob_name: "Directors and Officers" },
    { lob_id: 8, lob_name: "Inland Marine" },
  ],
};

export default mockData;
