const mockData = {
  status: "success",
  message: "Job records fetched successfully.",
  data: [
    {
      job_id: 115,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-14T06:52:00.011879",
      client: "FRP",
      account_name: "Account123",
      account_manager: "John",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 116,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-14T07:05:43.863204",
      client: "FRP",
      account_name: "Account123",
      account_manager: "John",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 120,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-14T08:59:02.301282",
      client: "FRP",
      account_name: "Account123",
      account_manager: "John",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 102,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-12T12:37:22.981755",
      client: "ABC Insurance",
      account_name: "Commercial Account",
      account_manager: "John Doe",
      user_submitted: "jdoe",
      frp_office: "New York Office",
      client_code: "CLI123",
    },
    {
      job_id: 103,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-12T12:39:00.203887",
      client: "ABC Insurance",
      account_name: "Commercial Account",
      account_manager: "John Doe",
      user_submitted: "jdoe",
      frp_office: "New York Office",
      client_code: "CLI123",
    },
    {
      job_id: 106,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-12T13:25:50.698318",
      client: "FRP",
      account_name: "Account123",
      account_manager: "Mark",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 108,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-13T11:04:43.321061",
      client: "FRP",
      account_name: "Account123",
      account_manager: "John",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 109,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-13T11:07:47.931567",
      client: "FRP",
      account_name: "Account123",
      account_manager: "John",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 110,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-13T11:30:35.894205",
      client: "FRP",
      account_name: "Account123",
      account_manager: "John",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 111,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-13T11:31:31.609272",
      client: "FRP",
      account_name: "Account123",
      account_manager: "John",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 112,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-13T11:33:28.181644",
      client: "FRP",
      account_name: "Account123",
      account_manager: "John",
      user_submitted: "John Doe",
      frp_office: "Office1",
      client_code: "001",
    },
    {
      job_id: 503,
      scenario_id: 1,
      scenario_name: "Autorenewal Direct Bill",
      lob_id: 1,
      lob_name: "General Liability",
      created_at: "2024-11-13T18:22:01.400522",
      client: null,
      account_name: null,
      account_manager: null,
      user_submitted: null,
      frp_office: null,
      client_code: null,
    },
  ],
  status_code: 200,
};

export default mockData;
