import { useQuery } from "@tanstack/react-query";
import getResultsByComparisonId from "../services/get-results-by-comparisonId-api";

const useResultsById = (comparisonId) => {
  return useQuery({
    queryKey: ["result", comparisonId],
    queryFn: () => getResultsByComparisonId(comparisonId),
  });
};
export default useResultsById;
