import mockData from "./mocks/form-data";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

export default async function getJobForm(jobKey) {
  let parsedResponse;
  const parsedJobKey = parseInt(jobKey);
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    parsedResponse = mockData;
  } else {
    const response = await fetch(`${REACT_APP_BACKEND_URL}api/scenario_config?`);
    parsedResponse = await response.json();
  }
  return {
    title: parsedResponse.data.find((i) => i.scenario_id === parsedJobKey)?.scenario_name,
    fields: parsedResponse.data
      .filter((i) => i.scenario_id === parsedJobKey)
      .map((i) => ({
        label: i.file_description,
        name: "file_" + i.scenario_file_id,
        scenario_file_id: i.scenario_file_id,
        type: "file",
        required: !i.optional,
      })),
  };
}
