import { useMemo } from "react";

const useSupportingDocsRowData = (data) => {
  return useMemo(() => {
    if (!data?.files) return [];
    return data.files.map((file) => ({
      file_id: file.file_id,
      fileName: file.file_name,
      fileDescription: file.file_description,
      fileUrl: file.file_location,
      owner: file.uploaded_by,
      uploadDate: file.uploaded_at,
    }));
  }, [data]);
};

export default useSupportingDocsRowData;
