import { useQuery } from "@tanstack/react-query";
import fetchActiveChecks from "../services/get-activeChecks-api";

/**
 * Custom React hook that fetches active checks/jobs using React Query.
 *
 * This hook encapsulates the logic for fetching active checks data using the
 * @tanstack/react-query library. It automatically handles caching, refetching,
 * and loading states for the active checks data.
 *
 * @returns {Object} React Query result object containing:
 *   - data: The fetched active checks data
 *   - isLoading: Boolean indicating if the request is in progress
 *   - isError: Boolean indicating if the request failed
 *   - error: Error object if the request failed
 *   - refetch: Function to manually trigger a refetch
 *   - Other React Query state and helper methods
 *
 * @example
 * const { data: activeChecks, isLoading, isError } = useActiveChecks();
 *
 * if (isLoading) return 'Loading...';
 * if (isError) return 'Error fetching active checks';
 * return <div>{activeChecks.length} active checks found</div>;
 */
const useActiveChecks = () => {
  return useQuery({
    queryKey: ["activeJobs"],
    queryFn: fetchActiveChecks,
  });
};

export default useActiveChecks;
