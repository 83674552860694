import { Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

const ViewResultsButtonRenderer = (props) => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const comparisonId = props.value;
  const handleClick = () => {
    if (jobId) {
      navigate(`/jobdetails/${jobId}/results/${comparisonId}`);
    }
  };

  return (
    <>
      <Button sx={{ textTransform: "none" }} onClick={handleClick}>
        View
      </Button>
    </>
  );
};

ViewResultsButtonRenderer.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ViewResultsButtonRenderer;
