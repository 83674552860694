import { useQuery } from "@tanstack/react-query";
import getClientDataByJobId from "../services/get-job-data-by-jobId";

const useJobDetailsData = (jobId) => {
  return useQuery({
    queryKey: ["jobDetailsData", jobId],
    queryFn: () => getClientDataByJobId(jobId),
  });
};

export default useJobDetailsData;
