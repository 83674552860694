import { useQuery } from "@tanstack/react-query";
import fetchFilesByFileId from "../services/get-file-by-fileid-api";

const useLoadDocument = (selectedDocument) => {
  console.log("selectedDocument", selectedDocument);
  return useQuery({
    queryKey: ["documentLoader", selectedDocument],
    queryFn: () => fetchFilesByFileId(selectedDocument.file_id),
  });
};

export default useLoadDocument;
