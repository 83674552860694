import { useQuery } from "@tanstack/react-query";
import fetchFilesByComparisonId from "../services/get-files-by-comparisonid";

const useFilesByComparison = (comparisonId) => {
  return useQuery({
    queryKey: ["jobFiles", comparisonId],
    queryFn: () => fetchFilesByComparisonId(comparisonId),
  });
};

export default useFilesByComparison;
