import { Outlet } from "react-router-dom";
import Header from "./Header";
const Root = () => {
  return (
    <>
      <Header />
      <div
        style={{
          padding: "0 2rem 2rem 0",
        }}
      >
        <Outlet />
      </div>
    </>
  );
};
export default Root;
