const jobDetailsPageStyles = {
  overlay: {
    position: "fixed",
    top: "22px",
    left: 0,
    right: 0,
    bottom: 0,
    background: "#D9D9D9CC",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  pdfViewer: {
    width: "100%",
    height: "90%",
    padding: "0 20px",
  },
  closeButton: {
    padding: "13px 37px",
    background: "#fff",
    color: "#000000",
    borderRadius: 0,
    cursor: "pointer",
    position: "fixed",
    bottom: "20px",
    left: "10px",
    border: "1px solid #000000",
    height: "40px",
  },
};
export default jobDetailsPageStyles;
